import React from 'react';

import Layout from '../components/layout';
import Navigation from '../components/header/Navigation';
import { PrivacyPolicyContent } from '../components/privacy-policy/PrivacyPolicyContent';
import FooterWrapper from '../components/index/FooterWrapper';

const ForPrivacyPolicyPage = () => (
  <Layout>
    <Navigation />
    <PrivacyPolicyContent />
    <FooterWrapper noLine={1} extraPadding={1} />
  </Layout>
);

export default ForPrivacyPolicyPage;
