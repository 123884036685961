import React from 'react';
import styled from '@emotion/styled';
import { Row } from 'antd';

import { mediaQueries, color } from '../variables';

const SectionWrapper = styled(Row)`
  padding: 10rem 12rem 6rem;
  background-color: ${color.white};
  background-size: cover;
  ${mediaQueries.xxxl} {
    padding: 10rem 9rem 1rem;
  }
  ${mediaQueries.xxl} {
    padding: 10rem 9rem 6rem;
  }
  ${mediaQueries.xl} {
    padding: 10rem 5rem 1rem;
  }
  ${mediaQueries.lg} {
    padding: 8rem 2rem;
  }
  ${mediaQueries.md} {
    padding: 8rem 2rem 2rem;
  }
  ${mediaQueries.sm} {
    padding: 7rem 1rem;
  }
`;

const CenteredHeader = styled.div`
  text-align: center;
`;

export const PrivacyPolicyContent = () => (
  <SectionWrapper>
    <CenteredHeader>
      <h4>MODERN HEALTH</h4>
      <h4>PRIVACY POLICY</h4>
      <p>Last Modified: July 1, 2019</p>
    </CenteredHeader>
    <p>
      Modern Life Inc. (“we”, “us”, or “Modern Health”) is committed to protecting your privacy.
      This Privacy Policy explains how your personal information is collected, used and disclosed by
      Modern Health and applies to your use of the Service. By accessing or using the Service, you
      signify that you have read, understood and agree to our collection, storage, use and
      disclosure of your personal information as described in this Privacy Policy and our Terms of
      Service. Any capitalized terms that are not defined in this Privacy Policy are defined in the
      Terms of Service.
    </p>
    <h4>1. WHAT INFORMATION DO WE COLLECT AND FOR WHAT PURPOSE? </h4>
    <p>The categories of information we collect can include: </p>
    <ul>
      <p>
        <li>
          <b>Information you provide to us directly.</b> We may collect personal information, such
          as your name, date of birth, phone number, location, and e-mail address when you when you
          register for our Service, sign up for our mailing list, enter a contest or sweepstakes, or
          otherwise communicate with us. We may also collect any communications between you and
          Modern Health and any other information you provide to Modern Health.
        </li>
      </p>
      <p>
        <li>
          <b>Information your Employer may provide.</b> We may collect information provided by your
          employer during the period in which the Services are being implemented. This information
          may include your suffix, sex, address1, address2, city, state, postal code, primary phone,
          secondary phone, employeeID (if applicable), employment start date, department, title, job
          code (if applicable), and office location.
        </li>
      </p>
      <p>
        <li>
          <b>Survey Responses.</b> We may collect your responses to surveys that we may ask you to
          complete for research purposes or to help direct Modern Health activities.
        </li>
      </p>
      <p>
        <li>
          <b>Information from your use of the Service. </b> We may collect information from your use
          of the Service, such as details of how you use the Service, your internet protocol address
          and/or unique mobile device ID number, details of financial transactions you participate
          in on the Service, your social media account IDs and any information shared with us via
          your social media accounts, and browser and device information collected through cookies,
          pixel tags, and other technologies.
        </li>
      </p>
    </ul>
    <h4>2. SHARING OF YOUR INFORMATION</h4>
    <p>
      We may share your personal information in the instances described below. For further
      information on your choices regarding your information, see the “Control Over Your
      Information” section below.
    </p>
    <p>We may share your personal information with: </p>
    <ul>
      <li>
        Other companies and brands owned or controlled by Modern Health, and other companies owned
        by or under common ownership as Modern Health, which also includes our subsidiaries (i.e.,
        any organization we own or control) or our ultimate holding company (i.e., any organization
        that owns or controls us) and any subsidiaries it owns. These companies will use your
        personal information in the same way as we can under this Policy;
      </li>
      <li>
        Third party vendors and other service providers that perform services on our behalf, as
        needed to carry out their work for us, which may provide mailing services, providing tax and
        accounting services, web hosting, or providing analytic services;
      </li>
      <li>
        Other parties in connection with a company transaction, such as a merger, sale of company
        assets or shares, reorganization, financing, change of control or acquisition of all or a
        portion of our business by another company or third party, or in the event of a bankruptcy
        or related or similar proceedings; and
      </li>
      <li>
        Third parties as required by law or subpoena or if we reasonably believe that such action is
        necessary to (a) comply with the law and the reasonable requests of law enforcement; (b) to
        enforce our Terms of Use [
        <a href="https://www.joinmodernhealth.com/terms-of-service/index.html">link</a>] or to
        protect the security or integrity of our Service; and/or (c) to exercise or protect the
        rights, property, or personal safety of Modern Health, our visitors, or others.
      </li>
    </ul>
    <p>
      We may also share information with others in an aggregated or otherwise anonymized form that
      does not reasonably identify you directly as an individual.
    </p>
    <h4>3. CONTROL OVER YOUR INFORMATION</h4>
    <p>
      <b>How to control your communications preferences:</b> You can stop receiving promotional
      email communications from us by clicking on the “unsubscribe link” provided in such
      communications. We make every effort to promptly process all unsubscribe requests. You may not
      opt out of service-related communications (e.g., account verification, transactional
      communications, changes/updates to features of the Service, technical and security notices).
    </p>
    <p>
      <b>Modifying or deleting your information:</b> If you have any questions about reviewing,
      modifying, or deleting your information, you can contact us directly at
      <a href="mailto:help@joinmodernhealth.com">
        {' '}
        <u>help@joinmodernhealth.com</u>
      </a>
      . We may not be able to modify or delete your information in all circumstances.
    </p>
    <h4>4. HOW WE STORE AND PROTECT YOUR INFORMATION</h4>
    <p>
      <b>Data storage and transfer:</b> Your information collected through our website may be stored
      and processed in the United States or any other country in which Modern Health or its
      affiliates or service providers maintain facilities. If you are located in the European Union
      or other regions with laws governing data collection and use that may differ from U.S. law,
      please note that we may transfer information, including personal information, to a country and
      jurisdiction that does not have the same data protection laws as your jurisdiction, and you
      consent to the transfer of information to the U.S. or any other country in which Modern Health
      or its parent, subsidiaries, affiliates, or service providers maintain facilities and the use
      and disclosure of information about you as described in this Privacy Policy.
    </p>
    <p>
      <b>Keeping your information safe:</b> We care about the security of your information and uses
      commercially reasonable physical, administrative, and technological safeguards to preserve the
      integrity and security of all information collected through our website. However, no security
      system is impenetrable, and we cannot 100% guarantee the security of our systems. In the event
      that any information under our control is compromised as a result of a breach of security, we
      will take reasonable steps to investigate the situation and, where appropriate, notify those
      individuals whose information may have been compromised and take other steps, in accordance
      with any applicable laws and regulations.
    </p>
    <h4>5. CHILDREN’S PRIVACY</h4>
    <p>
      Modern Health does not knowingly collect or solicit any information from anyone under the age
      of 13 on this website. In the event that we learn that we have inadvertently collected
      personal information from a child under the age of 13, we will delete that information as
      quickly as possible. If you believe that we might have any information from a child under 13,
      please contact us at{' '}
      <a href="mailto:help@joinmodernhealth.com">
        {' '}
        <u>help@joinmodernhealth.com</u>
      </a>
      .
    </p>
    <h4>6. LINKS TO OTHER WEB SITES AND SERVICES</h4>
    <p>
      The Services may contain links to and from third party websites of our business partners,
      advertisers, and social media sites and our users may post links to third party websites. If
      you follow a link to any of these websites, please note that these websites have their own
      privacy policies and that we do not accept any responsibility or liability for their policies.
      We strongly recommend that you read their privacy policies and terms and conditions of use to
      understand how they collect, use, and share information. We are not responsible for the
      privacy practices or the content on the websites of third party sites.
    </p>
    <h4>7. COOKIES AND WEB BEACONS</h4>
    <p>
      A "cookie" is a small file placed on your hard drive by some of our web pages. We, or third
      parties we do business with, may use cookies to help us analyze our web page flow, customize
      our Services, content and advertising, measure promotional effectiveness and promote trust and
      safety. Cookies are commonly used at most major transactional websites in much the same way we
      use them in the Service. You may delete and block all cookies from our Service, but parts of
      the Service will not work. We want to be open about our cookie use. Even if you are only
      browsing the Service, certain information (including computer and connection information,
      browser type and version, operating system and platform details, and the time of accessing the
      Service) is automatically collected about you. This information will be collected every time
      you access the Service and it will be used for the purposes outlined in this Privacy Policy.
      You can reduce the information cookies collected from your device. An easy way of doing this
      is often to change the settings in your browser. If you do that you should know that (a) your
      use of the Service may be adversely affected (and possibly entirely prevented), (b) your
      experience of this and other sites that use cookies to enhance or personalize your experience
      may be adversely affected, and (c) you may not be presented with advertising that reflects the
      way that you use our and other sites. You find out how to make these changes to your browser
      at this site:{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.allaboutcookies.org/manage-cookies/"
      >
        {' '}
        <u>www.allaboutcookies.org/manage-cookies/</u>
      </a>
      . Unless you have adjusted your browser settings so that it will refuse cookies, our system
      will send cookies as soon as you visit our Service. By using the Service you consent to this,
      unless you change your browser settings. Web beacons may be used to track the traffic patterns
      of users from one page to another in order to maximize web traffic flow. Our third-party
      advertising service providers may also use web beacons to recognize you when you visit the
      Service and to help determine how you found the Service. If you would like more information
      about this and to know your choices about not having this information used by these companies,
      please visit: the Digital Advertising Alliance’s website,{' '}
      <a target="_blank" rel="noopener noreferrer" href="https://www.aboutads.info/">
        {' '}
        <u>https://www.aboutads.info/</u>
      </a>
      , or the Network Advertising Initiative’s website,{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://networkadvertising.org/consumer/opt_out.asp"
      >
        {' '}
        <u>http://networkadvertising.org/consumer/opt_out.asp</u>
      </a>
      .
    </p>
    <h4>8. DO NOT TRACK SIGNALS</h4>
    <p>
      Your browser settings may allow you to automatically transmit a Do Not Track signal to
      websites and other online services you visit. We do not alter our practices when we receive a
      Do Not Track signal from a visitor’s browser because we do not track our visitors to provide
      targeted advertising. To find out more about Do Not Track, please visit{' '}
      <a target="_blank" rel="noopener noreferrer" href="https://www.allaboutdnt.com">
        {' '}
        <u>https://www.allaboutdnt.com</u>
      </a>
      .
    </p>
    <h4>9. HOW TO CONTACT US</h4>
    <p>
      If you have any questions about this Privacy Policy or the website, please contact us at
      <a href="mailto:info@joinmodernhealth.com">
        {' '}
        <u>info@joinmodernhealth.com</u>
      </a>
      .
    </p>
    <h4>10. PRIVACY SHIELD</h4>
    <p>
      Modern Health complies with the EU-U.S. Privacy Shield Framework and Swiss-U.S. Privacy Shield
      Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and
      retention of personal information transferred from the European Union and Switzerland to the
      United States. Modern Health has certified to the Department of Commerce that it adheres to
      the Privacy Shield Principles. If there is any conflict between the terms in this privacy
      policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn
      more about the Privacy Shield program, and to view our certification, please visit{' '}
      <a href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</a>.
    </p>
    <ul>
      <p>
        <b>ACCESS</b>
        <p>
          If you require access to your personal information to correct, amend, or delete your
          personal information, please see Section 3 (Control Over Your Information) above.
        </p>
      </p>
      <p>
        <b>CHOICE</b>
        <p>
          Modern Health may share your information with categories of third parties as set forth in
          Section 2 (Sharing Your Information) above. These third parties may have access to your
          personal information necessary to perform their functions but are restricted from using
          your personal information for purposes other than providing services for us or to us.
          Modern Health requires that its agents and service providers that have access to personal
          information received from the EEA and Switzerland provide the same level of protection as
          required by the EU-U.S. and Swiss-U.S. Privacy Shield Principles. We may be required to
          disclose your personal information in response to valid requests by public authorities,
          including to meet national security or law enforcement requirements. We do not share your
          personal information with anyone without your consent.
        </p>
      </p>
      <p>
        <b>ONWARD TRANSFER</b>
        <p>
          We are responsible for ensuring that our agents, service providers and other third parties
          to whom we disclose your personal information process the information in a manner
          consistent with our obligations under the EU-U.S. and Swiss-U.S. Privacy Shield
          Principles.
        </p>
      </p>
      <p>
        <b>RECOURSE AND ENFORCEMENT</b>
        <p>
          In compliance with the Privacy Shield Principles, Modern Health commits to resolve
          complaints about our collection or use of your personal information. EU and Swiss
          individuals with inquiries or complaints regarding our Privacy Shield policy should first
          contact Modern Health at
          <a href="mailto:help@joinmodernhealth.com">
            {' '}
            <u>help@joinmodernhealth.com</u>
          </a>
        </p>
      </p>
      <p>
        Modern Health has committed to refer unresolved Privacy Shield complaints to JAMS, an
        alternative dispute resolution provider located in the United States. If you do not receive
        timely acknowledgment of your complaint from us, or if we have not addressed your complaint
        to your satisfaction, please visit{' '}
        <a href="https://www.jamsadr.com/eu-us-privacy-shield">
          https://www.jamsadr.com/eu-us-privacy-shield
        </a>{' '}
        for more information or to file a complaint. The services of JAMS are provided at no cost to
        you.
      </p>
      <p>
        We have further committed to cooperate and comply with the panel of European data protection
        authorities (DPAs) and the Swiss Federal Data Protection and Information Commissioner (
        <a href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/task.html">
          https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/task.html
        </a>
        ) respectively in the resolution of any Privacy Shield complaints we do not otherwise
        satisfactorily address with you directly. Modern Health will comply with the advice given by
        such authorities with regard to human resources data transferred from the EU and Switzerland
        in the context of the applicable employment relationship.
      </p>
      <p>
        For purposes of enforcing compliance with the Privacy Shield, Modern Health is subject to
        the investigatory and enforcement authority of the US Federal Trade Commission.
      </p>
      <p>
        Under certain conditions, more fully described on the Privacy Shield website (
        <a href="https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint">
          https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint
        </a>
        ), you may invoke binding arbitration when other dispute resolution procedures have been
        exhausted.
      </p>
    </ul>
    <h4>11. CHANGES TO OUR PRIVACY POLICY</h4>
    <p>
      We may modify or update this Privacy Policy from time to time to reflect the changes in our
      business and practices so you should review this page periodically. When we change the policy
      in a material manner we will let you know and update the ‘last modified’ date at the top of
      this page. If you object to any changes, you may close your account. Continuing to use our
      Services after we publish changes to this Privacy Policy means that you are consenting to the
      changes.
    </p>
  </SectionWrapper>
);
